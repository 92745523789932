<template>
    <div>
      <c-search-box @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- 사업장 -->
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              label="대상년도"
              type="year"
              default="today"
              v-model="searchParam.participantYear"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-field
              label="대상자"
              v-model="searchParam.userId"
            />
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              :comboItems="statusItems"
              type="search"
              itemText="codeName"
              itemValue="code"
              name="requestFlag"
              label="요청상태"
              v-model="searchParam.requestFlag"
            ></c-select>
          </div> -->
        </template>
      </c-search-box>
      <c-table
        ref="table"
        title="참여자 현황 목록"
        tableId="table"
        :merge="grid.merge"
        :columns="grid.columns"
        :data="grid.data"
        :columnSetting="false"
        :isFullScreen="false"
        :usePaging="false"
        :expandAll="true"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </template>
  
  <script>
  import selectConfig from '@/js/selectConfig';
  export default {
    name: 'participantStatus',
    data() {
      return {
        grid: {
          merge: [
            { index: 0, colName: 'heaEtrParticipantStatusId' },
            { index: 1, colName: 'heaEtrParticipantStatusId' },
            { index: 2, colName: 'heaEtrParticipantStatusId' },
            { index: 3, colName: 'heaEtrParticipantStatusId' },
          ],
          columns: [
            {
              name: 'plantName',
              field: 'plantName',
              label: 'LBLPLANT',
              align: 'center',
              style: 'width:90px',
              fix: true,
              sortable: true,
            },
            {
              name: 'participantYear',
              field: 'participantYear',
              label: '대상<br>년도',
              style: 'width:60px',
              align: 'center',
              fix: true,
              sortable: true,
            },
            {
              name: 'participantRound',
              field: 'participantRound',
              label: '회차',
              style: 'width:60px',
              align: 'center',
              fix: true,
              sortable: true,
            },
            {
              name: 'participantTitle',
              field: 'participantTitle',
              label: '제목',
              style: 'width:150px',
              align: 'left',
              // type: 'link',
              fix: true,
              sortable: true,
            },
            {
              name: 'upDeptName',
              field: 'upDeptName',
              label: '팀',
              align: 'center',
              style: 'width: 90px',
              sortable: false,
              fix: true,
            },
            {
              name: 'deptName',
              field: 'deptName',
              label: '반',
              align: 'center',
              style: 'width: 90px',
              sortable: false,
              fix: true,
            },
            {
              name: 'userName',
              field: 'userName',
              label: '대상자',
              align: 'center',
              style: 'width: 80px',
              sortable: false,
              fix: true,
            },
            {
              name: 'age',
              field: 'age',
              style: 'width: 60px',
              label: '연령',
              align: 'center',
              sortable: true,
            },
            {
              name: 'longevityCount',
              field: 'longevityCount',
              label: '근속년수',
              align: 'left',
              style: 'width: 90px',
              sortable: false,
            },
            { 
              name: 'treatmentArea',
              field: 'treatmentArea',
              label: '치료부위',
              align: 'left',
              style: 'width: 250px',
              sortable: false,
            },
            { 
              name: 'participationCategoryName',
              field: 'participationCategoryName',
              label: '참여구분',
              align: 'left',
              style: 'width: 90px',
              sortable: false,
            },
            { 
              name: 'treatmentPeriod',
              field: 'treatmentPeriod',
              label: '치료기간',
              align: 'center',
              style: 'width: 170px',
              sortable: false,
            },
            {
              name: 'survey',
              field: 'survey',
              label: '설문조사',
              align: 'center',
              child: [
                { 
                  name: 'checklist1Anw',
                  field: 'checklist1Anw',
                  label: '선정 및<br>분류<br>적절성',
                  style: 'width: 60px',
                  sortable: false,
                },
                { 
                  name: 'checklist2Anw',
                  field: 'checklist12Anw',
                  label: '보건<br>관리자<br>전문성',
                  style: 'width: 60px',
                  sortable: false,
                },
                { 
                  name: 'checklist3Anw',
                  field: 'checklist3Anw',
                  label: '예방<br>도움',
                  style: 'width: 60px',
                  sortable: false,
                },
                { 
                  name: 'checklist4Anw',
                  field: 'checklist4Anw',
                  label: '시간<br>할애<br>적정성',
                  style: 'width: 60px',
                  sortable: false,
                },
                { 
                  name: 'checklist5Anw',
                  field: 'checklist5Anw',
                  label: '통증 및<br>증상<br>감소',
                  style: 'width: 60px',
                  sortable: false,
                },
                { 
                  name: 'checklist7Anw',
                  field: 'checklist7Anw',
                  label: '추천<br>여부',
                  style: 'width: 60px',
                  sortable: false,
                },
                { 
                  name: 'checklist8Anw',
                  field: 'checklist8Anw',
                  label: '종합<br>만족도',
                  style: 'width: 60px',
                  sortable: false,
                },
              ]
            },
            { 
              name: 'checklistTotal',
              field: 'checklistTotal',
              label: '총점',
              style: 'width: 60px',
              sortable: false,
            },
            {
              name: 'vas',
              field: 'vas',
              label: '통증척도(VAS)변화',
              align: 'center',
              child: [
                { 
                  name: 'checklist6BeforeScore',
                  field: 'checklist6BeforeScore',
                  label: '치료 전',
                  style: 'width: 60px',
                  sortable: false,
                },
                { 
                  name: 'checklist6AfterScore',
                  field: 'checklist6AfterScore',
                  label: '치료 후',
                  style: 'width: 60px',
                  sortable: false,
                },
                { 
                  name: 'increaseOrDecrease',
                  field: 'increaseOrDecrease',
                  label: '통증<br>증감',
                  align: 'left',
                  style: 'width: 60px',
                  sortable: false,
                },
                { 
                  name: 'increaseOrDecreaseDiff',
                  field: 'increaseOrDecreaseDiff',
                  label: '증감차',
                  style: 'width: 60px',
                  sortable: false,
                },
                { 
                  name: 'increaseOrDecreaseRate',
                  field: 'increaseOrDecreaseRate',
                  label: '증감률',
                  style: 'width: 60px',
                  sortable: false,
                },
              ]
            },
            {
              name: 'rate',
              field: 'rate',
              label: '통증 감소율',
              align: 'center',
              child: [
                { 
                  name: 'painReductionRate80',
                  field: 'painReductionRate80',
                  label: '80%이상<br>감소',
                  align: 'left',
                  style: 'width: 70px',
                  sortable: false,
                },
                { 
                  name: 'painReductionRate70',
                  field: 'painReductionRate70',
                  label: '70%이상<br>감소',
                  align: 'left',
                  style: 'width: 70px',
                  sortable: false,
                },
                { 
                  name: 'painReductionRate60',
                  field: 'painReductionRate60',
                  label: '60%이상<br>감소',
                  align: 'left',
                  style: 'width: 70px',
                  sortable: false,
                },
                { 
                  name: 'painReductionRate50',
                  field: 'painReductionRate50',
                  label: '50%이상<br>감소',
                  align: 'left',
                  style: 'width: 70px',
                  sortable: false,
                },
                { 
                  name: 'painReductionRate40',
                  field: 'painReductionRate40',
                  label: '40%이상<br>감소',
                  align: 'left',
                  style: 'width: 70px',
                  sortable: false,
                },
                { 
                  name: 'painReductionRate30',
                  field: 'painReductionRate30',
                  label: '30%이상<br>감소',
                  align: 'left',
                  style: 'width: 70px',
                  sortable: false,
                },
                { 
                  name: 'painReductionRate20',
                  field: 'painReductionRate20',
                  label: '20%이상<br>감소',
                  align: 'left',
                  style: 'width: 70px',
                  sortable: false,
                },
                { 
                  name: 'painReductionRate10',
                  field: 'painReductionRate10',
                  label: '10%이상<br>감소',
                  align: 'left',
                  style: 'width: 70px',
                  sortable: false,
                },
                { 
                  name: 'painReductionRate0',
                  field: 'painReductionRate0',
                  label: '감소<br>없음',
                  align: 'left',
                  style: 'width: 70px',
                  sortable: false,
                },
              ]
            },
            { 
              name: 'checklist9Anw',
              field: 'checklist9Anw',
              align: 'left',
              label: '의견',
              style: 'width: 400px',
              sortable: false,
            },
            { 
              name: 'remark',
              field: 'remark',
              align: 'left',
              label: '비고',
              style: 'width: 150px',
              sortable: false,
            },
          ],
          data: [],
        },
        searchParam: {
          plantCd: null,
          participantYear: '',
          requestFlag: null,
          userId: '',
        },
        editable: true,
        listUrl: '',
        popupOptions: {
          target: null,
          title: '',
          visible: false,
          isFull: false,
          top: '',
          param: {},
          closeCallback: null,
        },
        statusItems: [
          { code: 'N', codeName: '요청전' },
          { code: 'Y', codeName: '요청완료' },
        ],
        period: [],
      };
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
    },
    methods: {
      init() {
        this.editable = this.$route.meta.editable;
        this.listUrl = selectConfig.hea.etr.participant.survey.url;
        this.getList();
      },
      getList() {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      },
    }
  };
  </script>
  